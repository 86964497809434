import './index.css';

import reportWebVitals from './reportWebVitals';

const reactImport = import('react');
const reactDomImport = import('react-dom');
const app = import('./App');

Promise.all([
    reactImport, reactDomImport, app
]).then(asyncModules => {
    const [React, ReactDOM, App] = asyncModules;
    ReactDOM.render(
        <React.StrictMode>
            <App.default />
        </React.StrictMode>,
        document.getElementById('root')
    ); 
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
